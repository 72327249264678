.success-color{
    background-color: #d9f7be;
    padding: 5px;
}
.error-color{
    background-color: #ffccc7;
    padding: 5px;
}
.waiting-color{
    background-color: #ffe7ba;
    padding: 5px;
}
.question-box{
    border: 1px solid #DEE2E6;
    display: flex;
    justify-content: space-between;
}
.question-add-remove-button{
    cursor: pointer;
}