#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.logo{
  padding:20px;
}
.logo span{
  color: white;
}
.sidebar-scroll{
  height:80vh;
  overflow: hidden;
  overflow-y: scroll;
}
.sidebar-scroll::-webkit-scrollbar{
  width:10px;

}
.sidebar-scroll::-webkit-scrollbar-thumb{
  background-color: #00376B;
}
.sidebar-scroll::-webkit-scrollbar-track{
  width:10px;
}
.none-layout-background{
  background-image: url("/src/assets/images/login_background.jpg");
  background-position: center;
  background-size: cover;

}

