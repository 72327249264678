body{
  /*background:linear-gradient(to left, #B1C8E8, white);*/
  margin:0;
  padding:0;
  box-sizing:border-box;
  color:black;
}

.container{
  display:flex;
    align-items:center;
    justify-content:center;
    height:100vh;
    width:100%;
}
  .form-box{
      width:380px;
      /*height:450px;*/
      background-color:white;
      padding:20px;
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      opacity:1;
      border-radius:2%;
  }
.header-form{
  margin-bottom:15px;
}
button{
  margin-top:40px;
  margin-bottom:10px;
}
.message{
  display:flex;
  justify-content:space-between
}
.social{
  display:flex;
  justify-content:space-around;
  padding:30px;
}

.social i{
  font-size:35px;
}

.social i:hover{
  color:red;
  cursor:pointer;
}
.body-form{
    color:black !important;
}
.forRegis a{
  text-decoration: none;
}
