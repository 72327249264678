.panel {
    display: none;
    padding: 10px;
    /*border: 1px solid #D9D9D9;*/
    margin-left: 20px;
}

.showed {
    display: block;
    padding: 10px;
    /*border: 1px solid #D9D9D9;*/
    margin-left: 20px;
}

.row-hover:hover {
    background-color: white !important;
    border: 1px solid #ff7a45 !important;
}

.ant-input-number-lg input {
    height: 22px;
    width: 60px;
}
.ant-input-number-input{
    padding-left: 1px;
    padding-top: 0px;
    padding-right: 1px;
    padding-bottom: 0px;
}