footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    text-align: center;
}
.footer-div{
  width: 33%;
    color:black;
}
.footer-box{
  border-top: 1px solid #D9D9D9;
}