.print-box {
    display: none;
}

@media print {
    .print-box {
        width: 100%;
        display: block;
    }
    .print-box-item{
        width: 50%;
        height: 180px;
        display: inline-block;
        /*text-align: center;*/
    }
    .page-break{
        page-break-before: always;
        clear: both;
    }
}